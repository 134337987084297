import React from 'react';
import './footer.css';
import vielogo from '../../assets/vie-dark-2.png';
import { Link } from 'react-router-dom';

const Footer = () => {

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };
  return (
    <div className="vie__footer section__pading">
      

      <div className="vie__footer-links">
        <div className="vie__footer-links_logo">
          <img src={vielogo} alt="logo" />
        </div>
        <div className="vie__footer-links_div">
          <h4>Links</h4>
          <p><a onClick={() => scrollToSection('about')}>About Us</a></p>
          <p><a onClick={() => scrollToSection('contact')}>Contact Us</a></p>
          <p><a onClick={() => scrollToSection('services')}>Services</a></p>
        </div>

        <div className="vie__footer-links_div">
          <h4>Get In Touch</h4>
          <p>info@vie-solutions.com</p>
        </div>
      </div>
      <div className="vie__footer-copyright">
        <p>© 2024 Vie Solutions. All rights reserved.</p>
      </div>
    </div>
  )
}

export default Footer