import React, { useState } from 'react';
import './navbar.css';
import { HiMenuAlt3 } from "react-icons/hi";
import { CgClose } from "react-icons/cg";
import logo from '../../assets/vie-dark-2.png';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNav = () => {
    setIsOpen(!isOpen);
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    setIsOpen(false); // Close the navbar if open in mobile view
  };

  return (
    <nav className={`navbar ${isOpen ? 'navbar-open' : ''}`}>
      <div className="navbar-container">
      <a href="/">
        <img src={logo} alt="Vie Solutions Logo" className="navbar-logo" />
      </a>
        <ul className={`navbar-nav ${isOpen ? 'nav-active' : ''}`}>
          <li className="nav-item" onClick={() => scrollToSection('services')}>Services</li>
          <li className="nav-item" onClick={() => scrollToSection('about')}>About</li>
          <li className="nav-item" onClick={() => scrollToSection('contact')}>Contact</li>
          {/* Add other sections as needed */}
        </ul>
        <div className="navbar-toggle" onClick={toggleNav}>
          {isOpen ? <CgClose /> : <HiMenuAlt3 />}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;


