import React from 'react';
import { CTA, Navbar,Brands, Testimonials} from './components';
import { Footer, Header, Contact, About, Services} from './containers';
import './App.css'

const App = () => {
  return (
    <div className='App' id='app'>
      <>
        <Navbar />
        <Header />
        <Services />
        <Brands />
        <About />
        {/* <Testimonials /> */}
        <Contact />
        <Footer />  
      </>
    </div>
  )
}

export default App
