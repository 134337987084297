import React, { useState } from 'react';
import './about.css';
import { FaRocket, FaEye, FaHeart } from 'react-icons/fa';
import { motion } from 'framer-motion';

const aboutData = [
  {
    id: 'mission',
    icon: <FaRocket className="mvv-icon" />,
    title: 'Our Mission',
    description: [
      'Creating tailored software solutions',
      'Empowering businesses across industries',
      'Delivering on every promise',
      'Learning and adapting to challenges',
      'Implementing solutions with determination'
    ],
  },
  {
    id: 'vision',
    icon: <FaEye className="mvv-icon" />,
    title: 'Our Vision',
    description: [
      'Catalyzing transformation in tech',
      'Inspiring innovation and setting standards',
      'Being the go-to partner for software solutions',
      'Advancing the industry with our vision'
    ],
  },
  {
    id: 'values',
    icon: <FaHeart className="mvv-icon" />,
    title: 'Our Values',
    description: [
      'Guided by core values in all our work',
      'Committed to reliability and client trust',
      'Continuous learning at the technology forefront',
      'Embracing change as a source of innovation'
    ],
  },
];

const About = () => {
  const [expandedCard, setExpandedCard] = useState(null);

  return (
    <div className="about-container" id="about">
      <h2 className="about-title">The Foundation of Vie Solutions</h2>
      <div className="mvv-cards">
        {aboutData.map(({ id, icon, title, description }) => (
          <motion.div 
            key={id} 
            className="mvv-card" 
            onClick={() => setExpandedCard(expandedCard === id ? null : id)}
            layout
            initial={{ borderRadius: 10 }}
          >
            {icon}
            <h3>{title}</h3>
            <p> Learn More </p>
            {expandedCard === id && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                className="mvv-description"
              >
                <ul>
                  {description.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </motion.div>
            )}
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default About;
