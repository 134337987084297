import { 
  FaCode, FaShieldAlt, FaBrain, FaChartLine 
} from 'react-icons/fa';

const serviceData = [
  {
    icon: <FaCode />,
    title: 'App Development',
    description: 'Custom solutions',
    details: ['Responsive Design', 'E-commerce Platforms', 'CMS Development', 'Business System Integration']
  },
  {
    icon: <FaBrain />,
    title: 'AI & Machine Learning',
    description: 'Intelligent solutions',
    details: ['Predictive Analytics', 'NLP', 'Computer Vision']
  },
  {
    icon: <FaChartLine />,
    title: 'SEO & Marketing',
    description: 'Boost your presence',
    details: ['Search Engine Optimization', 'Social Media Marketing', 'Email Campaigns']
  },
  {
    icon: <FaShieldAlt />,
    title: 'Cybersecurity',
    description: 'Protect your digital assets',
    details: ['Threat Assessment', 'Incident Response', 'Compliance Audits']
  }
];

export default serviceData;
