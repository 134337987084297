import React, { useEffect } from 'react';
import './brands.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
// Import your logo images directly
import verragioLogo from '../../assets/verragio.png';
import parkerLogo from '../../assets/parker.png';
import vicLogo from '../../assets/victaulic-logo.png';
import aramarkLogo from '../../assets/aramark.png';
import rothLogo from '../../assets/roth.png';
import drsLogo from '../../assets/DRS-Logo.png';

const Brands = () => {
    useEffect(() => {
        AOS.init();
    }, []);

    const brandsData = [
        // { src: verragioLogo, alt: 'Verragio', className: 'brand-logo' },
        //{ src: oasdLogo, alt: 'OASD', className: 'brand-logo oasd-logo' }, // Added a specific class for OASD
        //{ src: tmoLogo, alt: 'TMO', className: 'brand-logo' },
        { src: parkerLogo, alt: 'Parker', className: 'brand-logo' },
        { src: vicLogo, alt: 'Victaulic', className: 'brand-logo' },
        { src: aramarkLogo, alt: 'Aramark', className: 'brand-logo' },
        // ...add more brands as needed
    ];

    return (
        <div className="brands-section" id="brands">
            <h2 className="brands-heading" data-aos="fade-up">Empowering Industry Leaders with Innovative Tech Partnerships</h2>
            <div className="brands-logos" data-aos="fade-up" data-aos-delay="100">
                {brandsData.map((brand, index) => (
                    <img key={index} src={brand.src} alt={brand.alt} className={brand.className} data-aos="zoom-in" data-aos-delay={`${100 + index * 100}`}/>
                ))}
            </div>
        </div>
    );
};

export default Brands;
