import React, { useEffect } from 'react';
import './header.css';
import { FaArrowCircleDown } from 'react-icons/fa'; // Example icon for scrolling
import CTA from '../../components/cta/CTA'; // Adjust the path as necessary
import logo from '../../assets/VieSolutions-03.png'; // Your logo path
import AOS from 'aos';
import 'aos/dist/aos.css';

const Header = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  const scrollToServices = () => {
    document.getElementById('services').scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <header className="vie__header" id='home'>
      <div className="header-content">
        <div className="header-text-wrapper">
          <div className="header-text header-animate" data-aos="fade-down">
            <h1>Transforming ideas into digital excellence since 1998.</h1>
            <div className="header-animate" data-aos="fade-up">
              <CTA />
            </div>
          </div>
        </div>
        <div className="header-logo header-animate" data-aos="zoom-in">
          <img className="vie__header-logo" src={logo} alt="Vie Solutions Logo" />
        </div>
      </div>
      <div className="header-scroller" onClick={scrollToServices}>
        <span>See What We Do <FaArrowCircleDown /></span>
      </div>
    </header>
  );
}

export default Header;
