import React from 'react';
import './cta.css';
import { Link } from 'react-router-dom';

const CTA = () => {
  return (
    <div className="cta">
      <h2 className="cta-title">Empower Your Business with Custom Solutions</h2>
      <p className='cta-text'>At VieSolutions, we’re committed to turning your visionary ideas into impactful digital experiences. Reach out to us and find out how we can help your business thrive in the digital era.</p>
      <Link to='/contact' className="cta-btn ">Get in touch</Link>
    </div>
  );
}

export default CTA;

