import React, { useState, useEffect } from 'react';
import './contact.css';
import { motion } from 'framer-motion';
import { FaCheckCircle } from 'react-icons/fa';
import serviceData from '../../serviceData'; // Ensure this path is correct
import logo from '../../assets/VieSolutions-03.png';
// import logo2 from '../../assets/logo2.png'; // Import the logo

const Contact = () => {
    const [formState, setFormState] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        service: '',
        businessType: '',
        message: ''
    });
    const [isSubmitted, setIsSubmitted] = useState(false);

    useEffect(() => {
        // AOS can be initialized here if you're using it
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(formState);
        setIsSubmitted(true);
        // Integrate with your backend or email service
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormState({ ...formState, [name]: value });
    };

    return (
        <div className="contact-container" id="contact">
            <div className="form-side">
                <h2 className="contact-heading">Let's Connect</h2>
                <motion.form className="contact-form" onSubmit={handleSubmit}>
                    <div className="input-group">
                        <input
                            type="text"
                            name="firstName"
                            placeholder="First Name *"
                            required
                            onChange={handleChange}
                        />
                        <input
                            type="text"
                            name="lastName"
                            placeholder="Last Name *"
                            required
                            onChange={handleChange}
                        />
                    </div>
                    <input
                        type="email"
                        name="email"
                        placeholder="Your Email *"
                        required
                        onChange={handleChange}
                    />
                    <input
                        type="text"
                        name="phone"
                        placeholder="Phone *"
                        required
                        onChange={handleChange}
                    />
                    <select name="service" required onChange={handleChange}>
                        <option value="">Select Service *</option>
                        {serviceData.map((service, index) => (
                            <option key={index} value={service.title}>{service.title}</option>
                        ))}
                    </select>
                    <input
                        type="text"
                        name="businessType"
                        placeholder="Your Business Type"
                        onChange={handleChange}
                    />
                    <textarea
                        name="message"
                        placeholder="Your Message"
                        onChange={handleChange}
                    />
                    <button type="submit" className="submit-button">
                        Contact us
                    </button>
                    {isSubmitted && (
                        <div className="submission-message">
                            <FaCheckCircle /> We received your message and will reach out to you within 24 hours.
                        </div>
                    )}
                </motion.form>
            </div>
            <div className="design-side">
                <img src={logo} alt="VS Logo" className="background-logo"/>
            </div>
        </div>
    );
};

export default Contact;
