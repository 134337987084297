import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { About, Services, Contact  } from './containers';
import './index.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'aos/dist/aos.css';
import 'swiper/swiper-bundle.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { motion } from "framer-motion";







ReactDOM.render(
    <Router>
        <Routes>
            <Route path='/' element={<App/>}/>
            <Route path ='/about' element={<About />} />
            <Route path ='/services' element={<Services />} />
            <Route path ='/contact' element={<Contact />} />
        </Routes>
    </Router>,

    document.getElementById('root')
);
