// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.feature {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px;
    background-color: #f8f9fa;
    color: #333;
  }
  
  .feature-content {
    max-width: 600px;
    text-align: center;
  }
  
  .feature-content h2 {
    font-size: 2.5em;
    margin-bottom: 20px;
  }
  
  .feature-content p {
    font-size: 1.2em;
    line-height: 1.5;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/feature/feature.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,yBAAyB;IACzB,WAAW;EACb;;EAEA;IACE,gBAAgB;IAChB,kBAAkB;EACpB;;EAEA;IACE,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;IAChB,gBAAgB;EAClB","sourcesContent":[".feature {\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    padding: 50px;\r\n    background-color: #f8f9fa;\r\n    color: #333;\r\n  }\r\n  \r\n  .feature-content {\r\n    max-width: 600px;\r\n    text-align: center;\r\n  }\r\n  \r\n  .feature-content h2 {\r\n    font-size: 2.5em;\r\n    margin-bottom: 20px;\r\n  }\r\n  \r\n  .feature-content p {\r\n    font-size: 1.2em;\r\n    line-height: 1.5;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
