import React from 'react';
import './feature.css';

const Feature = ({ title, text }) => {
  return (
    <section className="feature">
      <div className="feature-content">
        <h2>{title}</h2>
        <p>{text}</p>
      </div>
    </section>
  );
};

export default Feature;