import React from 'react';
import './testimonials.css';
import Slider from 'react-slick'; // Importing react-slick

const testimonials = [
    // {
    //   quote: "Working with the team at Vie Solutions has been a game-changer for Verragio. They understood the essence of our brand and transformed our online presence with a stunning e-commerce site. Our customers now enjoy a seamless shopping experience that reflects the quality and elegance of our jewelry, thanks to Vie Solutions' expertise and dedication to excellence.",
    //   author: "Mike Wortis, CEO, Verragio"
    // },
    // {
    //   quote: "Vie Solutions brought our vision to life by developing a robust banking application for T Mobile Money. Their attention to detail and innovative approach resulted in a user-friendly platform that has significantly enhanced our customer service experience. Their technical skill and project management made them an invaluable partner in our journey to financial technology excellence.",
    //   author: "Geoffrey Chenworth, CTO, T Mobile Money"
    // },
    {
      quote: "Vie Solutions has been instrumental in streamlining our patient intake process. They developed an intuitive system tailored to the fast-paced environment our nurses operate in, ensuring efficiency and accuracy. Their solution has not only improved our workflow but also enhanced patient satisfaction. The professionalism and technical prowess of Vie Solutions are unmatched.",
      author: "Bradley, Operations Director, OASD"
    },
    {
      quote: "Partnering with Vie Solutions has significantly impacted our operational efficiency. They engineered a bespoke software solution that allows us to monitor our warehouse machinery in real-time, ensuring peak performance at all times. Their ability to deliver complex solutions with ease has made Vie Solutions a trusted ally in our continuous improvement efforts.",
      author: "Denise Vicario, VP of Operations, Victaulic"
    }
  ];
  

const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: "linear",
    pauseOnHover: true
  };

const Testimonials = () => {
return(
<div className="testimonials-container">
    <div className="testimonials-section">
            <h2 className="about-title">What Our Clients Say</h2>
            <Slider {...sliderSettings}>
            {testimonials.map((testimonial, index) => (
                <div key={index} className="testimonial-slide">
                <p className="testimonial-quote">"{testimonial.quote}"</p>
                <footer className="testimonial-author">- {testimonial.author}</footer>
                </div>
            ))}
            </Slider>
    </div>
</div>
);};

export default Testimonials;