// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --font-family: 'Montserrat', sans-serif;

  --gradient-bar: radial-gradient(circle at 50% 50%, rgba(40, 61, 78, 1) 0%, rgba(32, 97, 143, 1) 50%, rgba(147, 160, 161, 1) 100%);
  --gradient-text: linear-gradient(90deg, rgba(40, 61, 78, 1) 0%, rgba(32, 97, 143, 1) 50%, rgba(147, 160, 161, 1) 100%);

  --color-bg: #FBFAF5;
  --color-footer: white;
  --color-text: black;
  --color-subtext: black;
}

body {
  margin: 0;
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Rest of your CSS rules... */



`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;EACE,uCAAuC;;EAEvC,iIAAiI;EACjI,sHAAsH;;EAEtH,mBAAmB;EACnB,qBAAqB;EACrB,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,SAAS;EACT,+BAA+B;EAC/B,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA,8BAA8B","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');\r\n\r\n:root {\r\n  --font-family: 'Montserrat', sans-serif;\r\n\r\n  --gradient-bar: radial-gradient(circle at 50% 50%, rgba(40, 61, 78, 1) 0%, rgba(32, 97, 143, 1) 50%, rgba(147, 160, 161, 1) 100%);\r\n  --gradient-text: linear-gradient(90deg, rgba(40, 61, 78, 1) 0%, rgba(32, 97, 143, 1) 50%, rgba(147, 160, 161, 1) 100%);\r\n\r\n  --color-bg: #FBFAF5;\r\n  --color-footer: white;\r\n  --color-text: black;\r\n  --color-subtext: black;\r\n}\r\n\r\nbody {\r\n  margin: 0;\r\n  font-family: var(--font-family);\r\n  -webkit-font-smoothing: antialiased;\r\n  -moz-osx-font-smoothing: grayscale;\r\n}\r\n\r\n/* Rest of your CSS rules... */\r\n\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
