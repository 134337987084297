import React, { useState } from 'react';
import './services.css';
import { motion } from 'framer-motion';
import { FaCode, FaBrain, FaBullhorn, FaLock } from 'react-icons/fa';

const serviceData = [
  {
    id: 'development',
    title: 'App Development',
    icon: <FaCode />,
    description: [
      'Responsive Application Design',
      'E-commerce Solutions',
      'Web/Mobile Applications',
      'Content Management Systems',
      'Maintenance and Support'
    ],
  },
  {
    id: 'ai',
    title: 'AI & Machine Learning',
    icon: <FaBrain />,
    description: [
      'Data Analysis and Visualization',
      'Machine Learning Models',
      'Natural Language Processing',
      'Predictive Analytics',
      'AI-driven Recommendation Systems'
    ],
  },
  {
    id: 'marketing',
    title: 'Marketing',
    icon: <FaBullhorn />,
    description: [
      'Search Engine Optimization',
      'Social Media Marketing',
      'Email Campaigns',
      'Content Marketing',
      'Pay-Per-Click Advertising'
    ],
  },
  {
    id: 'cybersecurity',
    title: 'Cybersecurity',
    icon: <FaLock />,
    description: [
      'Security Audits',
      'Compliance and Risk Management',
      'Incident Response',
      'Network Security Solutions',
      'End-to-End Encryption'
    ],
  },
  // Add more services as needed
];

const Services = () => {
  const [expandedService, setExpandedService] = useState(null);

  return (
    <div className="services-container" id="services">
      <h2 className="services-title">Our Services</h2>
      <div className="services-cards">
        {serviceData.map(({ id, icon, title, description }) => (
          <motion.div key={id} className="service-card" onClick={() => setExpandedService(expandedService === id ? null : id)}>
            <div className="service-icon">{icon}</div>
            <h3>{title}</h3>
            <p> Learn More </p>
            {expandedService === id && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                className="service-description"
              >
                <ul>
                  {description.map((service, index) => (
                    <li key={index}>{service}</li>
                  ))}
                </ul>
              </motion.div>
            )}
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Services;
