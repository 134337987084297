// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* cta.css */
.cta {
    background-color: #f4f4f4; /* Consider matching with header background */
    border-radius: 8px;
    padding: 1rem;
    display: inline-block;
    margin-top: 1rem; /* Adjust space above CTA */
}

.cta-title {
    font-size: 1.75rem; /* Adjust font size */
    color: #0a2640; /* Match header color scheme */
    margin-bottom: 2rem;
    font-weight: 500; /* Consistent font weight */
}

.cta-text {
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 2rem;
}

.cta-btn {
    background-color: #0a2640; /* Button color */
    color: #fff;
    padding: 0.5rem 1.5rem;
    border: none;
    border-radius: 8px;
    transition: background-color 0.3s ease;
    text-decoration: none;
    font-weight: 500;
    font-size: 1.1rem;
}

.cta-btn:hover {
    background-color: #071b2d; /* Darker shade on hover */
    cursor: pointer;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .cta {
    margin-top: 5rem; /* Less space on mobile */
    justify-content: center;
  }
  .cta-title, .cta-text{
    text-align: center; /* Centered text on mobile */
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/cta/cta.css"],"names":[],"mappings":"AAAA,YAAY;AACZ;IACI,yBAAyB,EAAE,6CAA6C;IACxE,kBAAkB;IAClB,aAAa;IACb,qBAAqB;IACrB,gBAAgB,EAAE,2BAA2B;AACjD;;AAEA;IACI,kBAAkB,EAAE,qBAAqB;IACzC,cAAc,EAAE,8BAA8B;IAC9C,mBAAmB;IACnB,gBAAgB,EAAE,2BAA2B;AACjD;;AAEA;IACI,iBAAiB;IACjB,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,yBAAyB,EAAE,iBAAiB;IAC5C,WAAW;IACX,sBAAsB;IACtB,YAAY;IACZ,kBAAkB;IAClB,sCAAsC;IACtC,qBAAqB;IACrB,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,yBAAyB,EAAE,0BAA0B;IACrD,eAAe;AACnB;;AAEA,2BAA2B;AAC3B;EACE;IACE,gBAAgB,EAAE,yBAAyB;IAC3C,uBAAuB;EACzB;EACA;IACE,kBAAkB,EAAE,4BAA4B;EAClD;AACF","sourcesContent":["/* cta.css */\r\n.cta {\r\n    background-color: #f4f4f4; /* Consider matching with header background */\r\n    border-radius: 8px;\r\n    padding: 1rem;\r\n    display: inline-block;\r\n    margin-top: 1rem; /* Adjust space above CTA */\r\n}\r\n\r\n.cta-title {\r\n    font-size: 1.75rem; /* Adjust font size */\r\n    color: #0a2640; /* Match header color scheme */\r\n    margin-bottom: 2rem;\r\n    font-weight: 500; /* Consistent font weight */\r\n}\r\n\r\n.cta-text {\r\n    font-size: 1.2rem;\r\n    color: #333;\r\n    margin-bottom: 2rem;\r\n}\r\n\r\n.cta-btn {\r\n    background-color: #0a2640; /* Button color */\r\n    color: #fff;\r\n    padding: 0.5rem 1.5rem;\r\n    border: none;\r\n    border-radius: 8px;\r\n    transition: background-color 0.3s ease;\r\n    text-decoration: none;\r\n    font-weight: 500;\r\n    font-size: 1.1rem;\r\n}\r\n\r\n.cta-btn:hover {\r\n    background-color: #071b2d; /* Darker shade on hover */\r\n    cursor: pointer;\r\n}\r\n\r\n/* Responsive adjustments */\r\n@media (max-width: 768px) {\r\n  .cta {\r\n    margin-top: 5rem; /* Less space on mobile */\r\n    justify-content: center;\r\n  }\r\n  .cta-title, .cta-text{\r\n    text-align: center; /* Centered text on mobile */\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
